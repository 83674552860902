@import './variables.scss';
@import './cropperjs.scss';
@import './animation.scss';

* {
    font-family: 'Arimo', sans-serif;
    margin: 0;
    padding: 0;
}

// progress bar color 
.mat-progress-bar-fill::after {
    background-color: $color-secondary;
}

// pagination menu arrows
.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: none !important;
}

// checkbox list
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
    background-color: $color-primary;
}

.mat-badge-content {
    background-color: $color-tertiary;
    color: $color-custom-white;
}

// scroll mat-dialog
.mat-dialog-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #0000004d;
    border-radius: 10px;
    background-color: $color-custom-white;
}

.mat-dialog-container::-webkit-scrollbar {
    width: 7px;
    background-color: $color-custom-white;
}

.mat-dialog-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #d3c3c3;
}
/*****************/

.connected-user-circle {
    width: 9px;
    height: 9px;
    background-color: #7CFC00;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border: 1px solid;
    position: absolute;
}

.position-connected-user-circle-page-events {
    margin-left: 30px;
    margin-top: -44px;   
}

.position-connected-user-circle-page-people {
    margin-left: 38px;
    margin-top: -56px;   
}

.position-connected-user-circle-page-profile {
    width: 14px;
    height: 14px;
    margin-left: 72px;
    margin-top: 5px;
}

/****** sticky ******/
.static-header {
    position: sticky;
    top: 0px;
    z-index: 1;
}

.static-modal-nav-followers-events-and-users {
    position: sticky;
    top: 60px;
    z-index: 1;
    background: white;
}

.static-sidebar-short {
    position: sticky;
    top: 60px;
}

.static-sidebar-large {
    position: sticky;
    top: -400px;
}

.static-nav {
    position: sticky;
    top: 60px;
    z-index: 1;
    background: white;
}

.static-back-event,
.static-back-message {
    position: sticky;
    top: 108px;
    z-index: 1;
    background: white;
}

.static-search-events-or-users-or-event-messages {
    position: sticky;
    top: 100px;
    z-index: 1;
    background: white;   
    transition: all 50ms;
    transform: translate(0, 5%);
}

.static-input-send-message {
    position: sticky;
    bottom: 0px;
    z-index: 1;
    background: white;   
}

/***********************/

/* time picker */
.timepicker__header {
    background-color: $color-tertiary !important;
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

.timepicker-period__warning {
    border: 1px solid;
    display: none;
}
/****************/

.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $color-tertiary !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: gray !important;
}

/* snackbar action */
.mat-simple-snackbar-action button {
    color: white;
}

/* modals */
.dialog-error {
    width: 30%;
}

.event-hour-clock {
    width: 60%;
}

.upload-image-user,
.dialog-user-send-message {
    width: 45%;
}

.dialog-filter-events,
.event-more-relevant-list-modal,
.dialog-events-preference {
    width: 50%;
}

.dialog-share-event,
.dialog-event-stats,
.delete-event,
.pause-or-enable-event,
.dialog-success,
.delete-user-messages,
.dialog-change-password,
.dialog-request-push-notifications,
.dialog-reported-event,
.dialog-user-denied-notifications,
.dialog-user-recommended-event,
.dialog-exit-create-event {
    width: 40%;
}

.users-or-events,
.users-followers,
.users-assistants-likes-followers,
.top-events-previous-and-current,
.events-of-users,
.followers-events-and-users,
.dialog-user-notifications {
    width: 42%;
    
    .mat-dialog-container {
        padding: 0px 10px 10px 10px;
    }
}

/************/

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $color-primary;
}

.mat-menu-item {
    font-family: $font-Arimo;
}
    
.mat-menu-item .mat-icon {
    color: $color-icon;
}

.mat-horizontal-stepper-header .mat-step-icon {
    background-color: $color-primary;
}

.mat-button-focus-overlay { 
    background-color: transparent; 
}

button:disabled {
    cursor: not-allowed !important;
    background-color: $color-btn-disabled !important;
}

.mat-ink-bar {
    background: $color-tertiary !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $color-primary !important;
}

.mat-tab-header-pagination-chevron {
    border-color: $color-tertiary;
}

.mat-calendar-body-selected {
    background-color: $color-tertiary;
}

.mat-option-text {
    font-size: 13px;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
    background-color: $color-primary;
}

.mat-slide-toggle-content {
    font-family: $font-Arimo;
}

/* responsive design */
@media screen and (max-width: 980px) {

    .static-search-events-or-users-or-event-messages {
        top: 50px;
    }

    .static-back-event {
        top: 56px;
    }

    .mat-horizontal-stepper-header {
        padding: 0px 10px !important;
    }
    
    .dialog-error,
    .dialog-success,
    .upload-image-user {
        width: 80%;
    }

    .dialog-filter-events,
    .dialog-share-event,
    .dialog-event-stats,
    .users-or-events,
    .users-followers,
    .users-assistants-likes-followers,
    .top-events-previous-and-current,
    .events-of-users,
    .event-more-relevant-list-modal,
    .delete-event,
    .pause-or-enable-event,
    .dialog-user-send-message,
    .followers-events-and-users,
    .delete-user-messages,
    .dialog-change-password,
    .dialog-events-preference,
    .dialog-request-push-notifications,
    .dialog-reported-event,
    .dialog-user-notifications,
    .dialog-user-denied-notifications,
    .dialog-user-recommended-event,
    .dialog-exit-create-event,
    .event-hour-clock {
        width: 100%;
    }

    .users-or-events {
        .mat-dialog-container {
            padding: 0px 3px 10px 3px;
        }
    }

}
