
$color-primary: #1e81ce;
$color-secondary: #4aa3df;
$color-tertiary: #0dd3ff;
$color-icon: #16a085;
$color-title: #303538;
$color-content-letters: #000000be;
$color-custom-white: #fcfcfc;
$color-btn-disabled: #b2daf28f;
$font-Arimo: 'Arimo', sans-serif;   
